/* App.css */

/* General Reset */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #000;
  color: #fff;
}

.App {
  text-align: center;
  height: 100vh;
}

/* Navbar Styling */
.navbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 3px solid white;
  border-radius: 55px;
}

.navicon {
  height: 31px;
}

.navicon-repo {
  height: 19.84px;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #000;
  border-radius: 50px;
  width: 80%;
}

.navbar-container .logo h1 {
  color: white;
}

.nav-links, .logo {
  list-style-type: none;
  display: flex;
}

.nav-links li, .logo li {
  margin-left: 20px;
}

.nav-links a, .logo a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.navbutton {
  width: fit-content;
  display: flex;
  align-items: center;
  height: fit-content;
  margin: auto;
  border-radius: 15px;
  cursor: pointer;
}

.navbutton:hover,
.socials:hover,
.circle:hover {
  box-shadow: 5px 5px 10px rgba(255, 255, 255, 0.7);
}

.logo-img {
  height: 85px;
}

/* Hamburger Menu Icon */
.menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menu-icon .bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
}

/* Home Page Styling */
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  padding: 0 50px 0 50px;
}

.home h1 {
  font-size: 3rem;
}

.home p {
  font-size: 1.2rem;
  margin: 10px 0;
}

.bullet {
  margin-left: 35px;
  list-style-type: none; 
  position: relative;
}

.bullet::marker {
  content: '•'; /* The bullet point symbol */
  color: white; /* Set the bullet color to white */
  font-size: 1.2em; /* Optional: Adjust the size of the bullet */
}

.icon2 {
  display: flex;
  margin: auto;
  height: 25%;
}

.titlerm, .title {
  font-size: 3vh;
}

.darkpaper-container {
  max-height: 400px; /* Adjust the height as needed */
  overflow-y: auto;
  width: 90%;
}

.triangletop {
  background-color: white;
  height: 10em;
  align-content: center;
  vertical-align: top;
  clip-path: polygon(100% 0, 0 0, 50% 100%);
  margin-bottom: 25px;
}

.trianglebottom {
  background-color: white;
  height: 10em;
  align-content: center;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  position: relative;
  bottom: -60px;
  left: 0;
  right: 0;
  margin-top: 25px;
}

.darkpaper-wrapper, .roadmap-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.titlerm {
  display: flex;
  margin: 35px auto 35px auto;
  text-align: center;
  justify-content: center;
  top: 75px;
  position: relative;
}

.line, .line-bottom {
  display: flex;
  width: 90%;
  height: 0px;
  margin-top: 25px;
  background-color: white;
  border: 3px solid white;
  align-items: center;
  top: 75px;
  position: relative;
}

.line-bottom {
  margin-bottom: 50px;
}

.circle {
  width: 50px;
  height: 50px;
  background-color: white;
  border: 3px solid white;
  border-radius: 50%;
  font-weight: bold;
  color: black;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 auto 0 auto;
}

.Home .h1, .title, .homep-container {
  font-size: 1.9vh;
}

.homep-container {
  text-align: left;
  margin-bottom: 50px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  z-index: 9998;
}

.modal-content {
  background-color: white;
  width: 390px;
  height: 490px;
  padding: 20px;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
  z-index: 9999;
}

.close {
  display: flex;
  margin: auto;
}

.quarter-wrapper {
  display: flex;
  margin: auto;
  text-align: center;
}

.soc-container {
  margin-top: 5%;
}

.soc-container, .soc-container2 {
  display: flex;
  margin: auto;
  width: 250px;
  align-items: center;
  justify-content: center;
}

.soc-container2 {
  margin-bottom: 25px;
}

.socials {
  width: 50px;
  margin: 5px;
  border-radius: 50%;
}



/* Media Query for Small Screens */
@media only screen and (max-width: 900px) {
  .nav-links {
    position: absolute;
    right: 0;
    top: 0;
    height: fit-content;
    width: 90%;
    background-color: #000;
    justify-content: center;
    border: 3px solid white;
    flex-direction: column;
    display: none; /* Hide menu by default */
    z-index: 10;
  }
  .nav-links.active {
    display: flex; /* Show menu when active */
  }
  .nav-links li {
    margin: 20px 0;
  }
  .menu-icon {
    display: flex; /* Show menu icon */
  }
  .nav-container {
    justify-content: space-between; /* Adjust navbar container alignment */
  }
  .logo-img {
    height: 50px;
  }
  .titlerm, .title {
    font-size: 3vh;
  }
  .circle {
    font-size: 2.5vh;
  }
  .modal-content {
    width: 300px;
    height: 540px;
  }
  .soc-container {
    margin-top: 100px;
  }
  .soc-container,
  .soc-container2 {
    width: 100%;
  }
}